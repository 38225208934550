.login-or-register input {
    margin-top: 7px;
    box-shadow: 1px 1px 10px #faecec;
}

.login-or-register div.con {
    margin-bottom: 20px;
}

.login-or-register button[type=submit] {
    background-color: #171719;
    color: white;
    width: 140px;
    height: 45px;
}

.login-or-register .login, .login-or-register .register {
    background-color: white;
    padding: 50px 30px;
}

.login-or-register select {
    margin-top: 7px;
    padding: 7px 15px;
    font-family: 'Quicksand';
    border: 1px solid #dddd;
    font-size: 16px;
}

.login-or-register select option {
    font-family: 'Quicksand';
}

.login-or-register .row .phone {
    margin-right: 40px;
}