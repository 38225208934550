.location {
    margin: 0;
}

.location img {
    width: 44px;
    height: 42;
    z-index: 1;
    cursor: pointer;
    margin: -1px;
    margin-right: 3px;
    border-radius: 5px 0 0 5px;
}

.input-con {
    display: flex;
    align-items: center;
    border: 2px solid #f0f0f0;
    border-radius: 5px;
}

.input-con input {
    padding: 5px;
    font-size: 18px;
    outline: none;
    width: 93%;
    border: none;
}

.location .address .input {
    width: 100%;
}

.location .phone {
    margin-top: 20px;
    padding: 5px;
    max-width: 300px;
}

.location .details {
    margin-top: 20px;
    padding: 5px;
}

.map {
    z-index: 9999999999;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    border: 1px solid gray;
    display: flex;
    flex-direction: column;
}

.map-info-con {
    z-index: 9999999999;
    width: 88vw;
    position: fixed;
    bottom: 0;
    padding: 0 5%;
}

.map-info {
    background-color: white;
    padding: 10px;
    font-size: 20px;
    border-radius: 10px 10px 0 0;
}

.autocomplete-dropdown-container {
    box-shadow: 1px 1px 15px #dddd;
    background-color: rgb(216, 146, 146);
    position: absolute;
    width: 340px;
    margin-top: 10px;
    z-index: 60;
}

.powered-by-google {
    display: flex;
    justify-content: flex-end;
    background-color: white;
    padding: 5px;

}

.powered-by-google img {
    height: 20px;
    width: 150px;
}

.location-search-input {
    color: gray;
    border: none;
}

.suggestion-item, .suggestion-item--active {
    padding: 10px;
    display: flex;
    align-items: center;
}

.autocomplete-dropdown-container .MuiSvgIcon-root {
    color: gray;
    margin-right: 5px;
    font-size: 20px;
}

.map-info button {
    background-color: #f04848;
    color: white;
    margin-left: auto;
}

.map-info p {
    word-wrap: break-word;
    width: 80%;
}

.location .label {
    margin-bottom: 15px;
}

.location .label span:nth-child(n+2) {
    color: #f04848;
    font-size: 18px;
}

.location:first-child .label span:first-child {
    color: #f04848;
    border: 2px dotted #f04848;
    margin-right: 7px;
    border-radius: 50%;
    padding: 2px 9px;
}

.location .label span:first-child {
    color: #f04848;
    border: 2px dotted #f04848;
    margin-right: 7px;
    border-radius: 50%;
    padding: 2px 7.5px;
}

.location .MuiIconButton-root {
    position: relative;
    bottom: 17px;
    left: 17px;
    padding: 10px 0;
    color: #f18d8d;
}

@media(max-width: 1000px) {
    .location {
        margin-bottom: 30px;
    }
}

@media(max-width: 350px) {
    .autocomplete-dropdown-container {
        width: 250px;
    }
}