.client {
    margin-top: 20px;
}

.client > p {
    margin-bottom: 30px;
    width: 80%;
}

.client .phone .input-con {
    border: 1px solid #f8abab;
    background-color: #fff5f4;

}

.client > .phone input {
    padding: 10px;
    border-radius: 0 5px 5px 0;
    border-left: 1px solid #f8abab;
}

.client .phone {
    max-width: 370px;
    margin: 15px 0;
}

.client .phone span {
    padding-left: 5px;
    padding-right: 5px;
}

.client .normal-input {
    border: 1px solid #f8abab;
    max-width: 350px;
}

.client button {
    background-color: #212122;
    color: white;
    width: 130px;
    height: 45px;
    letter-spacing: 1.5px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px 0;
}