.top {
    background-color: black;
    color: white;
    height: fit-content;
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 13.5px;
}

.top .right span,
.top .right span a  {
    margin-right: 15px;
    display: flex;
    align-items: center;
    transition: transform 500ms ease;
    cursor: pointer;
}

.top .right span:hover {
    transform: scale(1.1);
    color: #ffb6b6;
}

.top .right span .MuiSvgIcon-root {
    font-size: 18px;
    margin-right: 5px;
}

.top .social a {
    background-color: white;
    color: #f04848;
    margin-right: 15px;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px;
    transition: transform 500ms ease;
}

.top .social a:hover {
    background: transparent;
    color: #ffb6b6;
    border: 1px solid #ffb6b6;
    padding: 2px;
    transform: scale(1.1);
}

.header {
    position: sticky;
    top: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 15px;
    background-color: white;
    height: 65px;
    z-index: 5;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.06), 0 2px 5px 0 rgba(0, 0, 0, 0.06);   
}

.header .brand {
    margin-right: auto;
}

.header .brand img {
    height: 55px;
    cursor: pointer;
    transition: transform 500ms ease;
}

.header .brand img:hover {
    transform: scale(1.2);
}

.header nav {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.header .MuiSvgIcon-root {
    font-size: 25px;
}

.header .MuiIconButton-root {
    padding: 15px 7px;
}

.header .logout {
    position: relative;
    top: 50px;
}

.header nav a {
    font-size: 17px;
    margin-right: 30px;
    color: gray;
    transition: transform 500ms ease;
}

.header nav a:hover {
    transform:scale(1.2);
    color: #f04848;
}

.header nav a.focus {
    color: #f04848;
}

.header nav .book {
    padding: 10px;
    color: white;
    font-size: 15px;
    background: #d43636;
    border: none;
    font-weight: lighter;
}

.header nav .book:hover {
    color: #d43636;
    border: 1px solid #d43636;
    padding: 9px;
    background-color: white;;
}

.header nav .book-icon {
    color: #d43636;
    display: none;
}

.login-or-register .MuiSvgIcon-root {
    margin-right: 2px;
    font-size: 22px;
}

@media(max-width: 750px) {
    .header nav a {
        margin-right: 15px;
        font-size: 15px;
    }

    .header .brand img {
        height: 50px;
    }

    .header .MuiSvgIcon-root {
        font-size: 22px;
    }

    .top .right {
        margin-top: 10px;
    }

    .top .right span {
        margin-top: 10px;
        margin-right: 10px;
    }

    .header nav .book {
        display: none;
    }

    .header nav .book-icon {
        display: block;
    }
}