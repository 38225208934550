.category .item {
    margin-top: 20px;
    background: transparent;
    font-weight: bold;
    color: #f04848;
    font-size: 16px;
    padding: 0;
}

.category input {
    border: 2px solid #f0f0f0;
}