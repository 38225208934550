/* #171719 - black */
/* #da2510 - red */
/* #c5c6c8 - lightblue */

body {
  margin: 0;
  font-family: 'Quicksand';
  background-color: #f8f8f8;
  width: 100%;
}

@font-face {
  font-family: Quicksand;
  src: url('./fonts/Quicksand-Bold.ttf');
  font-weight: bold;
}

a {
  text-decoration: none;
  color: inherit;
}

.tabs {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
  background-color: #f8f8f8;
}

.tabs a, .tabs button {
  padding: 10px 20px;
  font-weight: bold;
  min-width: 100px;
  flex-wrap: wrap;
  color: gray;
}

.tabs .MuiMenuItem-root {
  color: #f04848;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.tabs .MuiMenuItem-root a {
  color: gray;
  font-family: 'Quicksand';
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}


.tabs .MuiMenuItem-root.active a {
  color: #f04848;
}

/* .tabs .MuiMenuItem-root.active {
  border-bottom: 3px solid #f18181;
} */

.tabs a .MuiSvgIcon-root {
  margin-right: 10px;
  font-size: 17
  33px;
}

.tabs button {
  padding: 5px 70px;
  background: transparent;
  border-radius: 0;
  margin: 0px;
  height: 60px;
  color: gray;
  font-size: 17px;
}

.tabs a.active, .tabs button.active {
  border-bottom: 4px solid #f18181;
  background-color: #f8f8f8;
  color: #f04848;
}

a.focus {
  background-color: #f04848;
}

.MuiSvgIcon-root {
  color: inherit;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.flex-start {
  justify-content: flex-start;
}

.tooltip {
  padding: 5px;
  font-size: 16px;
  font-family: 'Quicksand';
}

.box {
  padding: 20px;
  background-color: white;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.06), 0 2px 5px 0 rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  margin: 40px 0;
}

.box p {
  font-size: 16px;
}

.box p:nth-child(3) {
  color: #f04848;
}

.empty-box {
  display: flex;
  justify-content: center;
  align-items: center;
  color: gray;
  font-size: 20px;
  height: 70vh;
}

.normal-input {
  padding: 11px 10px;
  border-radius: 5px;
  border: 1px solid #c9c9c9dd;
  outline: none;
  width: 95%;
}

p b {
  font-size: 17px;
}

.group {
  margin-top: 40px;
}

.small-info {
  color: gray;
  font-size: 16px;
}

.complete-btn {
  color: white;
  width: 120px;
  height: 50px;
  background-color: #f04848;
  margin-bottom: 30px;
}

input {
  font-size: 16px;
  border-color: #c9c9c9dd;
  color: #6b6b6b;

}

input::placeholder {
  color: gray;
  font-family: 'Quicksand';
}

button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 15px;
  outline: none;
  border: none;
  margin-right: 20px;
  border-radius: 5px;
  font-size: 18px;
  font-family: 'Quicksand';
  font-weight: bold;
}

button:hover {
  cursor: pointer;
}

button .MuiSvgIcon-root {
  margin: 0 10px;
}

.container {
  padding: 0 3%;
}

.error, .success {
  margin-bottom: 20px;
  border-left: 5px solid rgb(255, 148, 148);
  background-color: #ffecec;
  color: darkred;
  padding: 10px;
  display: flex;
  align-items: center;
  width: 95%;
  font-size: 15px;
  font-weight: bold;
}

.success {
  border-left-color: rgb(0, 201, 0);
  background-color: #e4ffe4;
  color: green;
}

.error .MuiSvgIcon-root, .success .MuiSvgIcon-root {
  color: inherit;
  margin-right: 7px;
  font-size: 18px;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media(min-width: 750px) {
  .container {
    padding: 0 15%;
  }
}

@media(max-width: 450px) {
  .tabs .MuiMenuItem-root a span {
    display: none;
  }
}
