.track .input-con {
    background-color: white;
    padding-left: 7px;
    height: 50px;
    border: none;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.06), 0 2px 5px 0 rgba(0, 0, 0, 0.06);
    margin: 20px 0;
}

.track input {
    font-size: 16px;
}

.track .search {
    margin: 0;
    border-radius: 0;
    background-color: #171719;
    border-radius: 0px 5px 5px 0;
    height: inherit;
    width: 60px;
}

.track .search .MuiSvgIcon-root {
    color: white;
    font-size: 25px;
}

.track .item {
    background-color: white;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.06), 0 2px 5px 0 rgba(0, 0, 0, 0.06);
    margin-bottom: 50px;
    margin-top: 30px;
}

.track .item > div {
    padding: 5px;
    margin: 5px 0;
    border-bottom: 1px solid #dddd;
    padding: 10px;
}

.track .item .status {
    display: flex;
    padding: 0;
    padding: 20px 10px;
    border-bottom: 0;
}

.track .item span.pending,
.track .item span.completed {
    background-color: #fdf7bf;
    color: #474104;
    padding: 10px 15px;
    font-weight: bold;
    border-radius: 5px;
    margin-right: 10px;
}

.track .item span.completed {
    background-color: #d0ffd0;
    color: #003d00;
}

.track .item .cost {
    font-size: 25px;
    margin: 10px;
}

.track .item > div > div:nth-child(1) {
    font-weight: bold;
}

.track .to-item {
    margin-bottom: 10px;
    padding: 5px;
}

.track .to-item span {
    color: #f04848;
    margin-right: 10px;
    border: 1px dotted #f04848;
    font-size: 15px;
    border-radius: 50%;
    padding: 1px 8px;
}

.track .to-item:not(:first-child) span {
    padding: 1px 6.5px;
}