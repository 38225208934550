.toggle button {
    border: 1px solid #dbeaec;
    color: #f04848;
    background-color: #f4fdff;
    font-weight: bold;
    margin-top: 10px;
    font-size: 17px;
}

.toggle button:hover {
    border: 1px solid #f8abab;
    color: #494949;
    background-color: #fff5f4;
    transition: color 0.5s ease;
    transition: background-color 0.5s ease;
    transition: border-color 0.5s ease;
}

.toggle button.active {
    border: 1px solid #f8abab;
    color: #383838;
    background-color: #fff5f4;
}

.vehicle {
    margin-right: 30px;
    margin-bottom: 15px;
}

.weight {
    margin-bottom: 15px;
}

.weight div.con {
    border: 1px solid #c9c9c9dd;
    border-radius: 5px;
    padding: 10px;
    background-color: white;
}

.weight input {
    border: none;
    outline: none;
    font-size: 16px;
    background-color: transparent;
}

.book .phone .input-con {
    border: 1px solid #f8abab;
    background-color: #fff5f4;

}

.book > .phone input {
    padding: 10px;
    border-radius: 0 5px 5px 0;
    border-left: 1px solid #f8abab;
}

.book .phone {
    max-width: 300px;
    margin: 15px 0;
}

.book .phone span {
    padding-left: 5px;
    padding-right: 5px;
}

.total-btn {
    background-color: #171719;
    color: white;
    width: 200px;
    height: 50px;
    letter-spacing: 1.5px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.schedule {
    margin-top: 20px;
}

.schedule > div {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
}

.schedule input {
    margin-right: 20px;
    margin-top: 8px;
    padding: 5px;
    border: 1px solid #f8abab;
    border-radius: 3px;
    outline: none;
    background-color: #fffbfa;
}

.locations {
    display: grid;
    grid-template-columns: repeat(2, auto);
    gap: 30px;
    margin: 40px 0;
}

.add-address {
    color: white;
    background-color: #f04848;
    margin-left: auto;
    margin-top: 20px;
    border-radius: 3px;
    padding: 8px;
    padding-left: 0;
    margin-right: 0;
}

.floating-total {
    background-color: #171719;
    color: white;
    padding: 10px 12px;
    font-size: 18px;
    position: fixed;
    bottom: 0;
    z-index: 100;
    border-radius: 5px 5px 0 0;
}

.tracking-id {
    color: #4d4d4d;
}

.tracking-id button {
    display: inline;
    color: #f04848;
    cursor: pointer;
    background-color: transparent;
    font-weight: lighter;
    padding: 3px;
}

@media(min-width: 750px) {
    .floating-total {
        left: 30px;
    }
}

@media(max-width: 1000px) {
    .locations {
        display: block;
    }
}